import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_CONFIG } from '../../../../app/constants/api-config.constants';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { PAGE_ROUTER } from '../../../../app/constants/page-router.constants';
import { UserObject } from '../models/user.models';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  isUserLoggedIn: boolean = false;
  userInfo: any;
  token: any;
  refreshToken: any;
  authData: any;
  userData$ = new Subject();

  constructor(private http: HttpClient, private router: Router) {}

  authenticateUser(body: any): any {
    const url = API_CONFIG.AUTH_URL.login;
    return this.http.post(url, body);
  }

  setUserInfo(data: any) {
    const { customRole, menus, refresh_token, userinfo, token } = data;
    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refresh_token);
    localStorage.setItem('menus', JSON.stringify(menus));
    localStorage.setItem('userInfo', JSON.stringify(userinfo));
    localStorage.setItem('data', JSON.stringify(data));
    localStorage.setItem('customRole', JSON.stringify(customRole));
  }

  setActualLoggedInUser(actualyLoggedInUser: string) {
    localStorage.setItem('actualLoggedInUser', actualyLoggedInUser);
  }

  getActualLoggedInUser() {
    return localStorage.getItem('actualLoggedInUser');
  }

  getToken() {
    return localStorage.getItem('token');
  }

  logOutUser() {
    localStorage.clear();
    this.isUserLoggedIn = false;
    this.userInfo = {};
    this.router.navigate([PAGE_ROUTER.authentication.login]);
  }

  getUserObj(): UserObject {
    let userInfo: any = localStorage.getItem('userInfo');
    if (userInfo) {
      userInfo = JSON.parse(userInfo);
    }
    const userObj: UserObject = {
      firstName: userInfo.FirstName,
      lastName: userInfo.LastName,
      fullName: userInfo.FirstName + userInfo.LastName,
      role: userInfo.Role,
      userId: userInfo.UserId,
      userName: userInfo.UserName,
      programCodes:userInfo.txtProgram,
      forcePassword:userInfo.forcePassword,
      ysnAcb: userInfo.ysnAcb === 'TRUE' ? true : false,
    };
    return userObj;
  }

  getMenuList() {
    let menuList = [];
    const menuFromStorage = localStorage.getItem('menus');
    if(menuFromStorage && this.getUserObj().forcePassword !== "True") {
      try {
        menuList = JSON.parse(menuFromStorage);
      } catch {
        // this.toa
        this.logOutUser();
      }
      
    }
    return menuList;
  }

  postPasswordReset(body: any){
    return this.http.post(API_CONFIG.AUTH_URL.resetPassword,body);
  }

  postChangePassword(body: any){
    return this.http.post(API_CONFIG.AUTH_URL.changePassword,body);
  }


  getSSOLogon(userKey: string){
    return this.http.get(API_CONFIG.AUTH_URL.ssoLogon + '?UserKey=' +userKey);
  }

}
